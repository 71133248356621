import { Component, Input, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { CustomerStateService } from 'src/services/customer-state.service';
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';

@Component({
    selector: 'page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly onDestroy$ = new Subject<void>();
     
    @Input() height: string = '225px';
    @ViewChild('headerBackground', {static: true}) headerBackground: HTMLImageElement

    isMobile: boolean;
    public isProduction: boolean = false;
    public isLoaded: boolean = false;
    public backgroundImageExists: boolean = false;
    
    public backgroundImage: SafeResourceUrl;
    public logoImage: SafeResourceUrl;
    
    public noContextLogo: SafeResourceUrl
    public myWashClub: SafeResourceUrl

    public stickyHeaderClass: string = '';
    public isSticky: boolean = false;
    
    
    public innerWidth: number = window.innerWidth

    constructor(
        public customerStateService: CustomerStateService,
        public sanitizer:DomSanitizer,
        private appSettingsService: AppSettingsService,
    ){
        this.isProduction = this.appSettingsService.isProduction;
    }

    async ngOnInit() {
        console.log(" Creating header")

        
        this.makeHeaderSticky()
        // No context assets  
        this.noContextLogo = this.sanitizer.bypassSecurityTrustResourceUrl('./assets/WashMembership_SVG.svg');
        this.myWashClub = this.sanitizer.bypassSecurityTrustResourceUrl('./assets/WashPass_transparentCentered.svg');
        // Default assets when we have context but no images from the customer
        this.logoImage = this.noContextLogo;
        
        this.backgroundImage = this.sanitizer.bypassSecurityTrustResourceUrl('../../../assets/newDropletHeader.jpg');

        this.customerStateService.currentCustomerInfo$.pipe(takeUntil(this.onDestroy$)).subscribe( customerDashboard =>{
            if(customerDashboard){
                if(customerDashboard.image){
                    this.logoImage = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/svg;base64,' + customerDashboard.image);
                }
                if(customerDashboard.backgroundImage && customerDashboard.backgroundImage != customerDashboard.image){
                    this.backgroundImage = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/svg;base64,'+customerDashboard.backgroundImage);
                }
                this.isLoaded = true;
            }
        });
    }

    ngAfterViewInit(): void {
        this.makeHeaderSticky()
    }

    public makeHeaderSticky() {
        if(document.URL.includes("manage-my-membership") || document.URL.includes("login")){
            this.stickyHeaderClass = "sticky-header";
            this.isSticky = true;
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}