import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, map, takeUntil } from 'rxjs';
import { CustomerStateService } from 'src/services/customer-state.service';

@Component({
  selector: 'app-customer-context',
  templateUrl: './customer-context.component.html',
  styleUrls: ['./customer-context.component.scss']
})
export class CustomerContextComponent {
  
  private readonly onDestroy$ = new Subject<void>();
  
  constructor(
    public customerStateService: CustomerStateService,
    private route: ActivatedRoute
  ){
    const customerId$ = this.route.params.pipe(
      map(t => t.customerId),
    );
    customerId$.pipe(takeUntil(this.onDestroy$)).subscribe(customerId => {this.customerStateService.customerId = customerId;});
  }
    
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
