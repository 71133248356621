import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, first, firstValueFrom, forkJoin, map } from 'rxjs';
import { CustomerInformation } from 'src/models/customer';
import { ApiService } from './api.service';
import { SiteInformation } from 'src/models/site-information';
import { ISiteProduct } from 'src/models/site-product';
import { CustomerDetails } from 'src/models/customer-details';
import { ConsumerDetails, ConsumerPassDetails, ConsumerPassVehicle } from 'src/models/consumer-details';
import { ICustomerPassWithSiteProducts } from 'src/models/customer-pass';
import { Payment } from 'src/models/payment';
import { LocationInfo } from 'src/models/location-info';
import { ModalSiteInfo } from 'src/models/site-information';

@Injectable({
  providedIn: 'root'
})
export class CustomerStateService{
  private _customerId = new BehaviorSubject<string>(null);
  public currentCustomerId$ = this._customerId.asObservable();
    get customerId(){return this._customerId.getValue()}
    set customerId(newId: string){this._customerId.next(newId)}

  private _customerInfo = new BehaviorSubject<CustomerInformation>(null);
  public currentCustomerInfo$ = this._customerInfo.asObservable();
    get customerInfo(){return this._customerInfo.getValue()}

  private _customerDetails = new BehaviorSubject<CustomerDetails>(null);
    public customerDetails$ = this._customerDetails.asObservable();
    get customerDetails(){return this._customerDetails.getValue()}
    set customerDetails(newCustomerDetails: CustomerDetails){this._customerDetails.next(newCustomerDetails);}
  
  private _customerPasses = new BehaviorSubject<ICustomerPassWithSiteProducts[]>(null);
  public customerPasses$ = this._customerPasses.asObservable();
    get customerPasses(){return this._customerPasses.getValue()}
    set customerPasses(newCustomerPasses: ICustomerPassWithSiteProducts[]){this._customerPasses.next(newCustomerPasses)}
  private _currentCustomerPass = new BehaviorSubject<ICustomerPassWithSiteProducts>(null);
    get currentCustomerPass(){return this._currentCustomerPass.getValue()}
    set currentCustomerPass(newPass: ICustomerPassWithSiteProducts){this._currentCustomerPass.next(newPass);}
  public passToUpgrade: ICustomerPassWithSiteProducts;

  private _consumerDetails = new BehaviorSubject<ConsumerDetails>(null);
  public consumerDetails$ = this._consumerDetails.asObservable()
      get consumerDetails(){return this._consumerDetails.getValue()}
      set consumerDetails(newConsumerDetails: ConsumerDetails){this._consumerDetails.next(newConsumerDetails);}
  public vehicleNickname: string;
  public licensePlate: string;

  public favoredSite$ = new BehaviorSubject<SiteInformation | null>(null);
    get favoredSite(){return this.favoredSite$.getValue();}
  private _sites = new BehaviorSubject<SiteInformation[]>(null);
    get sites(){return this._sites.getValue();}
    set sites(newSites: SiteInformation[]){this._sites.next(newSites);}
  private _siteSubject$ = new BehaviorSubject<string>(null);
    get siteId() {return this._siteSubject$.getValue();}
    set siteId(item: string | null) {this._siteSubject$.next(item);}
  public favoredSiteId$ = this._siteSubject$.asObservable();

  private _purchaseHistory = new BehaviorSubject<Payment[]>(null);
  public purchaseHistory$ = this._purchaseHistory.asObservable();
    get purchaseHistory(){return this._purchaseHistory.getValue();}
    set purchaseHistory(newPayments: Payment[]){this._purchaseHistory.next(newPayments);}

  private _mobileNumber = new BehaviorSubject<string>(null);
    get mobileNumber(){return this._mobileNumber.getValue();}
    set mobileNumber(newNumber: string){this._mobileNumber.next(newNumber); sessionStorage.setItem("mobileNumber", newNumber);}

  private mapResponseSubject$ = new BehaviorSubject<LocationInfo | null>(null);
  public mapResponse$ = this.mapResponseSubject$.asObservable();

  public modalSites$: Observable<ModalSiteInfo[] | null>;

  // Used to display the loading screen overlay on manage-my-membership pages
  public pageLoaded$ = new BehaviorSubject<boolean>(false);
    get pageLoaded() {return this.pageLoaded$.getValue();}
    set pageLoaded(value: boolean) {this.pageLoaded$.next(value);}

  public BRONZE = "6db6b50e-f031-4fa8-b706-08df3ad5cd9f";
  public SILVER = "170c2796-cb17-4fcb-87e5-d9f778df1cc0";
  public GOLD = "227316fa-af47-4811-8992-949be9c35fe3";
  public PLATINUM = "bace3dcf-4f01-44f7-96dd-cd1e80c2952c";

  constructor(private apiService: ApiService){ // Gets customer info based on the id
    this.customerId = sessionStorage.getItem("customerId"); // Value is null on initial customer state service construction, but will be set for the second construction used by the receipt page
    // customerId will be updated by the customer-context component when the route params are read.
    // Once updated, the customerInfo will also update. Should only happen once for the initial null value, then again for the customer-context
    // On customerId update, update customerInfo and list of sites for that customer
    this._customerId.subscribe(id => {
      if(id){
        sessionStorage.setItem("customerId", id)
        this.apiService.getCustomerSites(id).subscribe(sites => this._sites.next(sites))
        this.apiService.getCustomerInformation(id).subscribe(customerInfo => {
          let colors = ["", customerInfo.tier1Banner, customerInfo.tier2Banner, customerInfo.tier3Banner, customerInfo.tier4Banner]
          for(let i=1; i<5; ++i){
            document.documentElement.style.setProperty(`--theme-tier-${i}-banner-color`,'#'+colors[i]);
            let red = parseInt(colors[i].substring(0,2),16);
            let green = parseInt(colors[i].substring(2,4),16);
            let blue = parseInt(colors[i].substring(4,6),16);
            let sparkleColor = "#"+this.max255(red+25).toString(16)+this.max255(green+25).toString(16)+this.max255(blue+25).toString(16);
            document.documentElement.style.setProperty(`--theme-tier-${i}-sparkle-color`,sparkleColor);
          }
        this._customerInfo.next(customerInfo)
        })
      }
    });
    
    this._customerInfo.subscribe( c => {
      if(c){
        this.getFavoredSite().then( t => {
          this.favoredSite$.next(t);
        })
      }
    });
  }

//-- Site related methods --// 
  async getSiteIdAsync(){
    // waits to return the first site id that is not null
    // Used to delay locations page until proper information is initialized (especially after a reload)
    return await firstValueFrom(this._siteSubject$.pipe(first(site => site != null), map(site => site)))
  }

  async getFavoredSite(): Promise<SiteInformation> {
    var siteInfo: SiteInformation;
    var siteId = sessionStorage.getItem("siteId");

    if (siteId != null && siteId !== "") {
      let sites = await firstValueFrom(this.apiService.getCustomerSites(this.customerId)); // Used to initialize sites for the receipt page after original customer state service is destroyed
      siteInfo = sites.find(t => t.id === siteId)
    } else {
      siteInfo = (this.customerInfo.favoredSite != null && this.customerInfo.favoredSite !== "") ? this.sites.find(t => t.id === this.customerInfo.favoredSite) ?? null : this.sites[0]
    }
    this.siteId = siteInfo.id;
    sessionStorage.setItem("siteId", this.siteId)

    return siteInfo;
  }

  async checkSite(customerId: string) {0.
    var siteId = sessionStorage.getItem("siteId");

    if (!siteId || siteId === "" || customerId === "undefined")
    {
      var customer = await firstValueFrom(this.apiService.getCustomerInformation(customerId))
      sessionStorage.setItem("siteId", customer.favoredSite);
      this.siteId = customer.favoredSite;
    }
  }

  async setCurrentSite() {
    var siteId = sessionStorage.getItem("siteId");
    var siteInfo: SiteInformation;

    if(this.customerId != null) {
      forkJoin([
        this.currentCustomerInfo$ = this.apiService.getCustomerInformation(this.customerId).pipe(first()),
        this._sites.pipe(first())
      ]).pipe(first()).subscribe(([customerInfo, sites]) => {
        if(sites && customerInfo){
          siteInfo = siteId ? sites.find(t => t.id === siteId) : sites.find(t => t.id === customerInfo.favoredSite) ?? null;
          this.siteId = siteInfo.id;
          this.favoredSite$.next(siteInfo);
        }
      })
    } else {
      this.currentCustomerInfo$ = this.apiService.getCustomerInformation(this.customerId);
      combineLatest([this.currentCustomerInfo$, this._sites], (customer, sites) => {
        siteInfo = (!siteId || siteId !== "") ? sites.find(t => t.id = siteId) : sites.find(t => t.id === customer.favoredSite) ?? null
        this.siteId = siteInfo.id
        this.favoredSite$.next(siteInfo)
      });
    }
  }

  forceSetCurrentActiveSite(siteId: string | null) {
    this.siteId = siteId;
    this._siteSubject$.next(siteId);
    this._sites.pipe(
      map(sites => { 
        this.favoredSite$.next( sites.find(newActiveSite => newActiveSite.id === siteId) )
      }));
      sessionStorage.setItem("siteId", siteId);
  }

  setMapResponse(response: LocationInfo | null) {
    this.mapResponseSubject$.next(response);
  }

  public getSiteProducts(): Observable<ISiteProduct[]> {
    return this.apiService.getSiteProducts(this.siteId, true);
  }

  //-- Methods used in manage-my-membership pages --//
  async getConsumerDetails(){
    this.consumerDetails = await this.apiService.getConsumerDetailsAsync(this.customerId, this.mobileNumber);
  }
  
  async getCustomerDetails(withImages: boolean = false){
    this.customerDetails = await this.apiService.getCustomerDetailsAsync(this.customerId, 'false');
    if(withImages){
      this.customerDetails.sites.forEach(siteDetails => {
        this.apiService.getSiteProducts(siteDetails.site.id, true).subscribe(siteProducts => {
          siteDetails.siteProducts = siteProducts;
        })
      })
    }
  }

  async checkDetails(withImages: boolean = false){
    if(!this.consumerDetails){
      await this.getConsumerDetails();
    } else {
      var consumerDetails = JSON.parse(sessionStorage.getItem("consumerDetails"));
      if (consumerDetails != null){
        this.consumerDetails = consumerDetails;
      }
    }
    if(!this.customerDetails){
      await this.getCustomerDetails(withImages);
    } else {
      var customerDetails = JSON.parse(sessionStorage.getItem("customerDetails"));
      if (this.customerDetails != null){
        this.customerDetails = customerDetails
      }
    }
  }

  setPassHeaderInfo(customerPasses: any[], siteProducts: ISiteProduct[]) {
    var cpwsp = new Array<ICustomerPassWithSiteProducts>(...customerPasses as any);
    for (let i = cpwsp.length - 1; i >= 0; i--) {
        const productTemplateIds = cpwsp[i].products.map(p => p.productTemplateId);
        cpwsp[i].siteProducts = siteProducts.filter(sp => productTemplateIds.includes(sp.productTemplateId)  && ((sp.productTemplateId == this.PLATINUM || sp.productTemplateId == this.GOLD || sp.productTemplateId == this.SILVER || sp.productTemplateId == this.BRONZE)));
    }
    // get all the unique product description picture keys in order that they appear
    // this will act as the "master list" of product descriptions
    var allPictureKeys = cpwsp.flatMap(t => t.siteProducts).flatMap(t => t.productDescriptions).map(t => t.pictureKey);
    var distinctPictureKeys = new Array<string>(...(new Set(allPictureKeys)));
    // Populate the product description array on each site product of each customer pass with dummy descriptions
    // to ensure that each site product contains all of the used picture keys. The dummy descriptions have null
    // `id` fields. This is to ensure that all of the product description images can be displayed by each
    // product, with the unused descriptions shown as disabled or unincluded.
    // This logic assumes that no picture key is used multiple times within a given product, and that picture
    // keys that are used across multiple products appear in the same order.
    for (let i = cpwsp.length - 1; i >= 0; i--) {
        const customerPass = cpwsp[i];
        for (let j = customerPass.siteProducts.length - 1; j >= 0; j--) {
            const siteProduct = customerPass.siteProducts[j];
            
            const pictureKeys = siteProduct.productDescriptions.map(t => t.pictureKey);
            for (let k = 0; k < distinctPictureKeys.length; k++) {
                const pictureKey = distinctPictureKeys[k];
                //This is a temporary fix to keep product descriptions from showing on products that arent gold silver or bronze
                //eventually will need to have a different push
                if(siteProduct.productTemplateId == this.PLATINUM || siteProduct.productTemplateId == this.GOLD || siteProduct.productTemplateId == this.SILVER || siteProduct.productTemplateId == this.BRONZE){
                    if (!pictureKeys.includes(pictureKey)) {
                        siteProduct.productDescriptions.push({
                            id: null,
                            description: null,
                            pictureKey: pictureKey,
                            descriptionImage: null,
                            descriptionOrder: k,
                            siteProductId: siteProduct.id
                        });
                    }
                }
            }
        }
    }
    
    this.customerPasses = cpwsp
  }

  max255(val: number){
    if (val>255) val = 255;
    return val;
}
}