import { Component, ElementRef, OnDestroy, OnInit, SecurityContext, ViewChild  } from "@angular/core";
import { Observable, Subject, asapScheduler, firstValueFrom, scheduled, shareReplay } from 'rxjs';
import { CustomerInformation } from 'src/models/customer';
import { Router } from "@angular/router";
import { ApiService } from 'src/services/api.service';
import { map, takeUntil, tap} from "rxjs/operators";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { faAngleDown, faCircle } from "@fortawesome/free-solid-svg-icons";
import { AppSettingsService } from "../app-settings/app-settings.service";
import { CustomerStateService } from "src/services/customer-state.service";
import { SiteInformation, ModalSiteInfo } from "src/models/site-information";
import { LocationInfo } from "src/models/location-info";
import { PassPurchaseService } from 'src/services/pass-purchase.service';
import { BsModalRef } from "ngx-bootstrap/modal";
import Lottie, { AnimationItem } from "lottie-web";

@Component({
  selector: 'app-site-landing',
  templateUrl: './site-landing.component.html',
  styleUrls: ['./site-landing.component.scss']
})
export class SiteLandingComponent implements OnInit, OnDestroy {
  faAngleDown = faAngleDown;
  faCircle = faCircle;
  modalRef?: BsModalRef;
  private readonly onDestroy$ = new Subject<void>();

  public customerId: Observable<string>
  public customerInformation: Observable<CustomerInformation>;

  public sites$: Observable<SiteInformation[]>;
  public modalSites$ = new Observable<ModalSiteInfo[]>(null);
  public inputValue: string;
  selectedIndex: number = -1;
  

  public isProduction: boolean = false;

  public explainerVideoLink: SafeResourceUrl = "https://www.youtube.com/embed/q81v7wEpLgU";
  
  public footerLogo: SafeResourceUrl;

  public canContinue: boolean = false;

  public mobileDownload: SafeResourceUrl = null;

  private readonly mapsApiKey: string;

  public info: LocationInfo = {lat: 0, lon: 0}

  public favoredSite$: Observable<SiteInformation | null> 

  public favoredSiteName: string = '';

  public isLoading: boolean = false;
  public showLoadScreen: boolean = false;
  private anim: AnimationItem;

  public modal: HTMLElement;
  public modalImg: HTMLImageElement;

  @ViewChild('aboutText') aboutTextElement: ElementRef;
  @ViewChild('explainerVideo') explainerVideoElement: ElementRef;

  constructor(
    private apiService: ApiService,
    public customerStateService: CustomerStateService,
    public passPurchaseService: PassPurchaseService,
    private router: Router,
    public sanitizer: DomSanitizer,
    private appSettingsService: AppSettingsService) { 
      this.mapsApiKey =`${this.appSettingsService.googleMapsKey}`;
      this.modalSites$ = this.apiService.modalSites$.pipe(shareReplay(1), tap());
      this.isProduction = this.appSettingsService.isProduction;
    }

  async ngOnInit() {
    this.favoredSite$ = scheduled(this.getfavoredSite(), asapScheduler)
    this.favoredSite$.pipe(takeUntil(this.onDestroy$)).subscribe(
      favoredSite => {
        this.favoredSiteName = favoredSite.name
      })
       
    var tempCustomerDashboard = await this.customerStateService.customerInfo
    this.customerInformation = await this.customerStateService.currentCustomerInfo$.pipe( 
      map(customerDashboard => {
        if (customerDashboard == null){
          customerDashboard = tempCustomerDashboard
        } else {
          this.footerLogo = this.sanitizer.bypassSecurityTrustUrl('data:image/svg;base64,' + customerDashboard.image);
          if(customerDashboard.mobileDownload != null){
            this.mobileDownload = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL,customerDashboard.mobileDownload);
          }

          // populate the customer styles/video link
          if(customerDashboard.explainerVideoLink != null && customerDashboard.explainerVideoLink.startsWith("https://www.youtube.com/embed/"))
          {
            this.explainerVideoLink = this.sanitizer.bypassSecurityTrustResourceUrl(customerDashboard.explainerVideoLink);
          } else if (customerDashboard.explainerVideoLink != null) {
            this.explainerVideoLink = this.sanitizer.bypassSecurityTrustResourceUrl(customerDashboard.explainerVideoLink.toString().replace("watch?v=", "embed/"));
          }
          else
          {
            this.explainerVideoLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.explainerVideoLink.toString())
            this.adjustVideoSize()
          }

          if (customerDashboard.aboutUsDescription == null || customerDashboard.aboutUsDescription == "")
          {
            customerDashboard.aboutUsDescription = "Thank you for your monthly wash pass purchase. Your pass includes 15 washes per month. Passes automatically renew each month. Cancel/suspend your pass anytime in the app"
            this.adjustVideoSize()
          }
        }
      return customerDashboard;
    }));

    // Checks if the passPurchaseService has already been initialized ( basically to check if initData() has been run )
    // if initData has not run yet, we run it to initialize the site data and site products data based on the current site
    // This check is also run in pass-purchase.component.ts
    this.passPurchaseService.isDataLoaded$.pipe(takeUntil(this.onDestroy$)).subscribe(
      async (isLoaded) => {
          if(isLoaded  != true){
              await this.passPurchaseService.initData();
          }
      });
    this.passPurchaseService.isLoading$.pipe(takeUntil(this.onDestroy$)).subscribe(
      (loading) => {
        this.isLoading = loading
        if(loading == false) {
          if(this.modal){
            this.router.navigate([`/customer/${this.customerStateService.customerId}/passes`, "pass-selection"]);
          }
        }
      }
    )

    this.anim = Lottie.loadAnimation({
      container: document.getElementById('loadingGraphic'),
      renderer: 'svg',
      rendererSettings: {
        className: 'loading-graphic'
      },
      loop: true,
      autoplay: false,
      path: '../../assets/loading_bubbles.json'
    });
  }

  async getfavoredSite() {
    var siteInformation: SiteInformation;

    var sites = await firstValueFrom(this.apiService.getCustomerSites(this.customerStateService.customerId))
    var customerInfo = await firstValueFrom(this.apiService.getCustomerInformation(this.customerStateService.customerId))

    if (this.customerStateService.siteId != null && this.customerStateService.siteId !== "")
    {
      siteInformation = sites.find(t => t.id === this.customerStateService.siteId)
    }
    else 
    {
      if (customerInfo.favoredSite != null && customerInfo.favoredSite !== "")
      {
        siteInformation = sites.find(t => t.id === customerInfo.favoredSite) ?? null
      }
      else
      {
        siteInformation = sites[0]
      }
    }
    return siteInformation;
  }

  CloseModal() {
    const modalDiv = document.getElementById("wash-select-modal");
      if (modalDiv != null) {
        modalDiv.style.display = "none";
      }
  }

  async washPassButtonClick(){
    //if loaded go to pass selection
    if(this.isLoading){
      this.modal = document.getElementById('loadingModal');
      this.modal.style.display = "flex";

      this.anim.play();

    } else {
      this.router.navigate([`/customer/${this.customerStateService.customerId}/passes`, "pass-selection"]);
    }
  }

  toggleBackground(index: number): void {
    this.selectedIndex = index;
  }

  async membershipButtonClick() {
    this.router.navigate([`/customer/${this.customerStateService.customerId}/login`]);
  }

  contactButtonClick() {
    this.router.navigate([`/customer/${this.customerStateService.customerId}/contact-us`]);
  }

  termsButtonClick() {
    this.router.navigate([`/customer/${this.customerStateService.customerId}/terms-of-service`])
  }

  setNewActiveSiteClick(siteId: string, siteName: string){
    this.favoredSiteName = siteName;
    this.isLoading = true;
    this.customerStateService.forceSetCurrentActiveSite(siteId);
    this.canContinue = true;
  }

  getCustomerIdFromSite(customerId: Observable<string>) {
    this.customerId = customerId;
    return customerId;
  }

  adjustVideoSize() {
    const aboutTextHeight = this.aboutTextElement.nativeElement.offsetHeight;
    const explainerVideoElement = this.explainerVideoElement.nativeElement;

    // Adjust the size of the video container based on the height of the text
    if (aboutTextHeight < explainerVideoElement.offsetHeight)
    {
      explainerVideoElement.style.height = explainerVideoElement.offsetHeight + 'px';
    }
    else
    {
      explainerVideoElement.style.height = aboutTextHeight + 'px';
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
  }
}
