import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, firstValueFrom } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ICustomerPassWithSiteProducts } from 'src/models/customer-pass';
import { IPromo } from 'src/models/promo';
import { IPassPurchaseDetails } from 'src/models/purchase-details';
import { IPassPaymentAdditionalInfo, WNPaymentResponse } from 'src/models/worldnet-models';
import { ApiService } from 'src/services/api.service';
import { CustomerInformation } from 'src/models/customer';
import { CustomerStateService } from 'src/services/customer-state.service';
import { IConsumer } from 'src/models/consumer';

@Injectable({
  providedIn: 'root'
})
export class PassPurchaseService {
  public platinum : string = "bace3dcf-4f01-44f7-96dd-cd1e80c2952c";
  public gold: string = "227316fa-af47-4811-8992-949be9c35fe3";
  public silver: string = "170c2796-cb17-4fcb-87e5-d9f778df1cc0";
  public bronze: string = "6db6b50e-f031-4fa8-b706-08df3ad5cd9f";
  public customerId: string = null;
  public customerInformation$ = new Observable<CustomerInformation>(null);

  public consumer: IConsumer;

  private _purchaseDetails = new BehaviorSubject<IPassPurchaseDetails>({
    customerPass: null,
    isGiftPurchase: null,
    phoneNumber: '',
    recipientPhoneNumber: '',
    emailAddress: '',
    activationDate: null,
    monthsDuration: null,
    monthlyPrice: null,
    tax: null,
    surcharge: null,
    userConfirmed: false,
    receipt: null,
    toName: null,
    fromName: null,
    promoCode: null,
    customerId: null,
    promoDiscount: null,
    promoName: null
  })
  private _passIndex = new BehaviorSubject<number>(null);
    get passIndex(){return this._passIndex.getValue()}
  private _themingTier = new BehaviorSubject<'platinum'|'gold'|'silver'|'bronze'|'primary'>('primary');
    get themingTier(){return this._themingTier.getValue()}
  public price = new BehaviorSubject<number>(null);
  private isDataLoaded = new BehaviorSubject<boolean>(null);
  private isLoading = new BehaviorSubject<boolean>(false);

  private _customerPassesWithSiteProducts = new BehaviorSubject<ICustomerPassWithSiteProducts[]>(null);
    get customerPassesWithSiteProducts(){return this._customerPassesWithSiteProducts.getValue()}; 
  
  public purchaseDetails$ = this._purchaseDetails.asObservable();
  public isDataLoaded$ = this.isDataLoaded.asObservable();
  public isLoading$ = this.isLoading.asObservable();
  
//-- Initializer methods --//
  constructor (
    private apiService: ApiService,
    public customerStateService: CustomerStateService
  ) {}

  public async initData() {
    this.isLoading.next(true);
    this.customerStateService.favoredSiteId$.pipe(  // verifies siteId / favoredSiteId$ exists and returns an observable to an array of the products and passes
      switchMap(siteId => {
        if (siteId == null){
          return of([],[]);
        }
        return forkJoin([this.apiService.getSiteProducts(siteId, true), this.apiService.getSiteCustomerPasses(siteId, true, true)])
      }),
      catchError(error => of([],[]))
    )
    .subscribe({                                  // Runs various functions when the observer^^ emits
      next: ([siteProducts, customerPasses]) => {
        
        if(siteProducts && customerPasses){
          this.isLoading.next(true);
          
          // Merge customer passes with site products by matching the customer pass products' productTemplateIds with site products' productTemplateIds
          var cpwsp = new Array<ICustomerPassWithSiteProducts>(...customerPasses as any); // cpwsp = customer pass with site products
          for (let i = cpwsp.length - 1; i >= 0; i--){
            // productTemplateIds is an array of the productTemplateIds for all the products of given pass
            const productTemplateIds = cpwsp[i].products.map(p => p.productTemplateId);
            cpwsp[i].siteProducts = siteProducts.filter(sp => productTemplateIds.includes(sp.productTemplateId));
          }
          this._customerPassesWithSiteProducts.next(cpwsp);

          var allPictureKeys = cpwsp.flatMap(t => t.siteProducts).flatMap(t => t.productDescriptions).map(t => t.pictureKey);
          var allIconStrings = cpwsp.flatMap(t => t.siteProducts).flatMap(t => t.productDescriptions).map(t => t.descriptionImage);
          var distinctPictureKeys = new Array<string>(...(new Set(allPictureKeys))); // uses Set to get the set of all unique elements from the allPictureKeys array
          var disctinctIconStrings = new Array<string>(...(new Set(allIconStrings)));

          for (let i = cpwsp.length - 1; i >= 0; i--) {
            const customerPass = cpwsp[i];
            for (let j = customerPass.siteProducts.length - 1; j >= 0; j--) {
              const siteProduct = customerPass.siteProducts[j];

              const pictureKeys = siteProduct.productDescriptions.map(t => t.pictureKey);
              for (let k = 0; k < distinctPictureKeys.length; k++) {
                const pictureKey = distinctPictureKeys[k];
                const iconString = disctinctIconStrings[k];
                if (siteProduct.productTemplateId == this.gold || siteProduct.productTemplateId == this.silver || siteProduct.productTemplateId == this.bronze) {
                  if (!pictureKeys.includes(pictureKey)) {
                    siteProduct.productDescriptions.push({
                        id: null,
                        description: null,
                        pictureKey: pictureKey,
                        descriptionImage: iconString,
                        descriptionOrder: k,
                        siteProductId: siteProduct.id
                    });
                }
                }
              }
            }
          }
          if(!this.isDataLoaded.getValue()){
            this.isDataLoaded.next(true);
            this.isDataLoaded.complete();
          }
          this.isLoading.next(false);
        }
        },
        error: err => {
          console.error(err)
          this.isDataLoaded.next(false);
          this.isLoading.next(false);
        }
      })
  }

  initPassPurchasePage(): [number, string, ICustomerPassWithSiteProducts] {
    return [this.passIndex, this.themingTier, this.details.customerPass];
  }

//-- General methods --//
  public resetModel(): void {
    const newValue: IPassPurchaseDetails = {
        customerPass: null,
        isGiftPurchase: null,
        phoneNumber: '',
        recipientPhoneNumber: '',
        emailAddress: '',
        activationDate: null,
        monthsDuration: null,
        monthlyPrice: null,
        tax: null,
        surcharge: null,
        userConfirmed: false,
        receipt: null,
        toName: null,
        fromName: null,
        promoCode: null,
        customerId: null,
        promoName: null,
        promoDiscount: null
    };
    this._purchaseDetails.next(newValue);
    this._themingTier.next('primary');
    this._passIndex.next(null);
    this.price.next(null);
  }

  get customerPassesWithSiteProducts$() : Observable<ICustomerPassWithSiteProducts[]>{
    return this._customerPassesWithSiteProducts.asObservable();
  }

  public get details() {
    return this._purchaseDetails.getValue();
  }

  getSiteId() {
    this.customerStateService.favoredSite$.pipe(
      map( site=> {
        return site.id.toString();
      })
    )
  }

  async getTaxAndSurcharge(customerPassId: string = null){
    const model = this.details;
    const values = await this.apiService.getTaxAndSurcharge(customerPassId != null ? customerPassId : this.details.receipt ? this.details.receipt.PASSID : this.details.customerPass.id);
    model.tax = values[0]
    model.surcharge = values[1]
    this._purchaseDetails.next(model);
  }

  public setCustomerPass(customerPass: ICustomerPassWithSiteProducts): void {
    const model = this.details;
    const index = this.customerPassesWithSiteProducts.findIndex(value => value.id === customerPass.id);

    let tierLevel: 'platinum'|'gold'|'silver'|'bronze'|'primary';

    if(customerPass.products.find(t => t.productTemplateId === this.platinum)) { tierLevel = 'platinum'; }
    else if(customerPass.products.find(t => t.productTemplateId === this.gold)) { tierLevel = 'gold'; }
    else if(customerPass.products.find(t => t.productTemplateId === this.silver)) { tierLevel = 'silver'; } 
    else if (customerPass.products.find(t => t.productTemplateId === this.bronze)) { tierLevel = 'bronze'; }
    model.customerPass = customerPass;
    model.monthlyPrice = customerPass.passCost;
    this.price.next(customerPass.passCost);
    this._purchaseDetails.next(model);
    this._themingTier.next(tierLevel);
    this._passIndex.next(index);
  }

  public setPhoneNumber(phoneNumber: string): void {
    const model = this.details;
    model.phoneNumber = phoneNumber;
    this._purchaseDetails.next(model);
  }

  public parsePhoneNumber(phoneNumber: string): string{
    return phoneNumber.substring(0,3)+'-'+phoneNumber.substring(3,6)+'-'+phoneNumber.substring(6,10);
  }
  
//-- Gift related methods --//
  public setIsGiftPurchase(value: boolean): void {
    const model = this.details;
    model.isGiftPurchase = value;
    this._purchaseDetails.next(model);
  }

  public setGiftRecipientInfo(recipient: string, recipientPhone: string) {
    const model = this.details;
    model.toName = recipient;
    model.recipientPhoneNumber = recipientPhone;

    this._purchaseDetails.next(model);
  }

  public setGiftPurchaserDetails( purchaser: string, purchaserPhone: string, purchaserEmail: string, monthCount: number) {
    const model = this.details;
    model.fromName = purchaser;
    model.phoneNumber = purchaserPhone;
    model.emailAddress = purchaserEmail;
    model.monthsDuration = monthCount;

    this._purchaseDetails.next(model);
  }

  public setActivationDate(date: string | number): void {
    const model = this.details;
    const activationDate = new Date(date);                          // need to make date is in local time
    activationDate.setUTCHours(0, 0, 0, 0);                         // first zero-out any hour/min/second/millisecond components
    activationDate.setMinutes(activationDate.getTimezoneOffset());  // shift by timezone offset to ensure Date is midnight of selected day in user's timezone
    model.activationDate = activationDate;
    model.userConfirmed = false;
    this._purchaseDetails.next(model);
  }

//-- Promo code methods --//
  async validatePromo(code: number, phoneNumber: string, customerId: string): Promise<IPromo> {
    return await this.apiService.validatePromo(code, phoneNumber, customerId);
  }

  public setPromoCode(priceOff: number, promoCode: number, id: string,  promoName: string){
    const model = this.details;
    model.monthlyPrice -= priceOff;
    model.promoDiscount = priceOff
    model.userConfirmed = true;
    model.promoCode = promoCode;
    model.customerId = id;
    model.promoName = promoName;

    this._purchaseDetails.next(model);
  }

  public resetPromoCode(){
    const model = this.details;
    model.monthlyPrice = model.customerPass.passCost;
    model.userConfirmed = false;
    model.promoCode = null;
    model.promoName = null;
    model.promoDiscount = null;
    this._purchaseDetails.next(model);
    this.price.next(model.monthlyPrice);
  }

//-- Payment & receipt methods --//
  getHostedPaymentPage(receiptUrl: string, customerId: string, price: number, passPaymentInfo: IPassPaymentAdditionalInfo, siteId: string): Observable<any>{
    return this.apiService.getHostedPaymentPage(receiptUrl, customerId, price, passPaymentInfo, siteId);
  }

  public async postPassPurchase(siteId: string, receipt: WNPaymentResponse, customerPassId:string, textURL: string, thankYouURL: string) {    
    return await this.apiService.postPassPurchase(siteId, receipt, customerPassId, thankYouURL, this.consumer.id);
  }

  public async postGiftPassPurchase(siteId:string, receipt: WNPaymentResponse, customerPassId: string, textURL: string, thankYouUrl: string, consumerId?:string) {
    return await this.apiService.postGiftPassPurchase(siteId, receipt, this.details, customerPassId, textURL, thankYouUrl, consumerId);
  }

  public setReceiptCustomerPass(customerPass: ICustomerPassWithSiteProducts): void {
    const model = this.details;
    model.customerPass = customerPass;
    this._purchaseDetails.next(model);
  }

  async setWorldNetReceipt(value: WNPaymentResponse){
    const info: IPassPaymentAdditionalInfo =
    {
      consumerId: value.CONSUMERID,
      passId: value.PASSID,
      passCost: value.PASSCOST,
      duration: value.DURATION,
      phone: value.PHONE,
      recipientPhone: value.RECIPIENTPHONE,
      email: value.EMAIL,
      activationDate: value.ACTIVATIONDATE,
      fromName: value.FROMNAME,
      toName: value.TONAME,
      customerId: value.CONSUMERID,
      promoName: this.details.promoName,
      promoCode: value.PROMOCODE
    };

    if (info) {
      if (this.details.customerPass == null) {
        console.log("Pass is null with receipt pass id:", info.passId)
        this.apiService.getCustomerPass(info.passId).subscribe(t => {
          this.setReceiptCustomerPass(t);
        })
      }
      console.log("Checking/creating consumer")
      if(info.recipientPhone === ""){
        this.consumer = await firstValueFrom(this.apiService.safeCreatePartialAccount(this.customerStateService.customerId, info.phone, info.passId).pipe(map(cons => {console.log("consumer:",cons); return cons;})))
      } else {
        this.consumer = await firstValueFrom(this.apiService.safeCreatePartialAccount(this.customerStateService.customerId, info.recipientPhone, info.passId).pipe(map(cons => {console.log("consumer:",cons); return cons;})))
      }

    }
    const model = this.details;
    if (info) {
      model.monthlyPrice = Number(info.passCost);
      model.emailAddress = info.email;
      model.phoneNumber = info.phone;
      model.recipientPhoneNumber = info.recipientPhone;
      model.activationDate = info.activationDate ? new Date(info.activationDate) : null;
      model.monthsDuration = Number(info.duration);
      model.toName = info.toName;
      model.fromName = info.fromName;
      model.promoCode = parseInt(info.promoCode);
    }

    model.receipt = value;
    model.receipt.ADDITIONALINFO = JSON.stringify(info);
    this._purchaseDetails.next(model);
  }
}
