import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { ICustomerPass } from 'src/models/customer-pass';
import { ISiteProduct, ISiteProductDescription } from 'src/models/site-product';
import { CustomerInformation } from 'src/models/customer';
import { CustomerStateService } from 'src/services/customer-state.service';
import { PassPurchaseService } from 'src/services/pass-purchase.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'purchase-footer',
    templateUrl: './purchase-footer.component.html',
    styleUrls: ['../../../scss/pass-purchase.scss']
})
export class PurchaseFooterComponent {
    private onDestroy$ = new Subject<void>()
    public customerInformation: CustomerInformation = null;

    @ViewChild('priceWrapper', { static: true }) priceWrapper: ElementRef<HTMLDivElement>;
    @ViewChild('bannerBaseFooter', { static: true }) bannerBaseFooter: ElementRef<HTMLCanvasElement>;
    
    @Input() pass: ICustomerPass;
    @Input() index: number;

    @Input() products: ISiteProduct[];
    @Input() monthCount: number = null;

    @Input() priceOverride: number = null;
    public passPrice: number;

    public tier: string;
    public productDescriptions: ISiteProductDescription[]; //treatment icons

    // customer dynamic styles/images
    public baseColor: string;
        private red: number;
        private green: number;
        private blue: number;
    private lightColor: string;
    private darkColor: string;
    public sparkleColor: string;
    public primaryTextColor: string;

    private bannerStripes = document.createElement("canvas");
    private canvasHeight = 54;
    private stripeWidths = [28, 52, 35, 150]; // Distance covered by a stripe, width of thick stripe, light thin stripe, total width of stripes


    @HostListener('window:resize')
    windowResize() {
        this.drawBanner(this.bannerBaseFooter);
    }

    constructor(
        private passPurchaseService: PassPurchaseService,
        public customerStateService: CustomerStateService 
        ) {}
    ngOnInit(){
        this.passPurchaseService.price.pipe(takeUntil(this.onDestroy$)).subscribe(
            data =>{
                console.log("Data!")
                    if(this.priceOverride == null){
                        this.passPrice = data;
                    } else {
                        this.passPrice = this.priceOverride
                    }
        });

        if(this.products != null){
            if(this.products.find(t => t.productTemplateId === this.passPurchaseService.platinum)) { this.tier = 'platinum'; }
            else if(this.products.find(t => t.productTemplateId === this.passPurchaseService.gold)) { this.tier = 'gold'; }
            else if(this.products.find(t => t.productTemplateId === this.passPurchaseService.silver)) { this.tier = 'silver'; } 
            else if (this.products.find(t => t.productTemplateId === this.passPurchaseService.bronze)) { this.tier = 'bronze'; }
        }
        else if(this.pass != null){
            if(this.pass.products.find(t => t.productTemplateId === this.passPurchaseService.platinum)) { this.tier = 'platinum'; }
            else if(this.pass.products.find(t => t.productTemplateId === this.passPurchaseService.gold)) { this.tier = 'gold'; }
            else if(this.pass.products.find(t => t.productTemplateId === this.passPurchaseService.silver)) { this.tier = 'silver'; } 
            else if (this.pass.products.find(t => t.productTemplateId === this.passPurchaseService.bronze)) { this.tier = 'bronze'; }
        }
        this.initBanner();

        this.windowResize();
    }

    max255(val: number){
        if (val>255) val = 255;
        return val;
    }

    private initBanner(): void {
        switch (this.tier){
            case 'platinum':
                this.baseColor = document.documentElement.style.getPropertyValue('--theme-tier-4-banner-color');
                break;
            case 'gold':
                this.baseColor = document.documentElement.style.getPropertyValue('--theme-tier-3-banner-color');
                break;
            case 'silver':
                this.baseColor = document.documentElement.style.getPropertyValue('--theme-tier-2-banner-color');
                break;
            case 'bronze':
                this.baseColor = document.documentElement.style.getPropertyValue('--theme-tier-1-banner-color');
                break;
            default:
                console.error("Unknown tier level: "+this.tier);
                break;
        }

        if (this.baseColor != null) {
            this.red = parseInt(this.baseColor.substring(1,3),16);
            this.green = parseInt(this.baseColor.substring(3,5),16);
            this.blue = parseInt(this.baseColor.substring(5,7),16);
            this.lightColor = "rgb("+(this.red+35)+' '+(this.green+35)+' '+(this.blue+35)+')';
            this.darkColor = "rgb("+(this.red-17)+' '+(this.green-17)+' '+(this.blue-17)+')';
        }

        this.bannerStripes.height = this.canvasHeight;
        this.bannerStripes.width = this.stripeWidths[3];

        let ctx = this.bannerStripes.getContext("2d");
        ctx.moveTo(0,this.canvasHeight);
        ctx.beginPath();
        ctx.lineTo(this.stripeWidths[3]-this.stripeWidths[0], this.canvasHeight);
        ctx.lineTo(this.stripeWidths[3], 0);
        ctx.lineTo(this.stripeWidths[0], 0);
        ctx.lineTo(0, this.canvasHeight);
        ctx.closePath();
        ctx.fillStyle = this.darkColor;
        ctx.fill();

        ctx.moveTo(this.stripeWidths[1], this.canvasHeight);
        ctx.beginPath();
        ctx.lineTo(this.stripeWidths[1]+this.stripeWidths[0], 0);
        ctx.lineTo(this.stripeWidths[0]+this.stripeWidths[1]+this.stripeWidths[2], 0);
        ctx.lineTo(this.stripeWidths[1]+this.stripeWidths[2], this.canvasHeight);
        ctx.lineTo(this.stripeWidths[1], this.canvasHeight);
        ctx.closePath();
        ctx.fillStyle = this.lightColor;
        ctx.fill();
    }

    public drawBanner(bannerBase: ElementRef, widthScalar:number=1) {
      
        const canvas = bannerBase.nativeElement;
        canvas.width = window.innerWidth*widthScalar;
      
        canvas.height = this.canvasHeight;
        let ctx = canvas.getContext('2d');
        let vw = canvas.width/100.0;
        let vwmax = canvas.width;
      
        ctx.fillStyle = this.baseColor;
        ctx.fillRect(0, 0, vwmax, this.canvasHeight);
        
        // New new version
        const endCapBuffer = (vwmax < 960 ? 58 : vwmax > 1493 ? 90 : 6.042*vw);
        const startPoint = vwmax - 8.5*vw - endCapBuffer - this.stripeWidths[3] - this.stripeWidths[1];
        const capStart = startPoint + 88 + this.stripeWidths[1] + this.stripeWidths[2];
        moveTo(startPoint, this.canvasHeight);
      
        const textCollisionWidth = 450;
        
        // If it won't run into the text too much, draw the stripes
        if (vwmax > textCollisionWidth) {              
            canvas.getContext("2d").drawImage(this.bannerStripes, startPoint, 0);
        }
      
        // darker end cap
        ctx.moveTo(capStart, this.canvasHeight); // bottom left
        ctx.beginPath();
        ctx.lineTo(capStart+this.stripeWidths[0], 0); //top left
        ctx.lineTo(vwmax, 0);
        ctx.lineTo(vwmax, this.canvasHeight);
        ctx.lineTo(capStart, this.canvasHeight);
        
        ctx.closePath();
        ctx.fillStyle = this.darkColor;
        ctx.fill();
      }

      ngOnDestroy(){
        this.onDestroy$.next(null);
      }
}