import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomerStateService } from 'src/services/customer-state.service';
import { Observable, first, takeUntil, Subject, firstValueFrom, map, catchError, of } from 'rxjs';
import { ApiService } from 'src/services/api.service';
import { isValidPhoneNumber } from '../utils/string-helpers';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPwdComponent implements OnInit, OnDestroy {
    private readonly numbers = ['0', '1','2','3','4','5','6','7','8','9','Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'];

    public consumerId: string = null;
    public phoneNumber: string;
    public validNumber: boolean = false;

    public tfaToken: string; // two factor authentication token
    public newPassword: string;
    public confirmPassword: string;

    public errMsg: string;

    constructor(
        private customerStateService: CustomerStateService,
        private router: Router, 
        private apiService: ApiService
    ){}

    ngOnInit(){
    }

    checkForNumber(event: KeyboardEvent){
        if(this.numbers.includes(event.key)){
            this.errMsg = null;
        } else {
            event.preventDefault()
        }
    }

    toLogin(){
        this.router.navigate([`customer/${this.customerStateService.customerId}/login`])
    }

    clearConsumer(){
        this.consumerId = null;
        this.phoneNumber = null;
        this.validNumber = false;
        this.tfaToken = null;
        this.newPassword = null;
        this.confirmPassword = null;
        this.errMsg = null;
    }

    
    async getConsumer(){
        if(this.phoneNumber && isValidPhoneNumber(this.phoneNumber)){
            let consumerId = await firstValueFrom(this.apiService.getConsumerByNumber(this.customerStateService.customerId, this.phoneNumber).pipe(
                catchError(err => {
                    this.errMsg = "* No account found.";
                    return of(null);
                }), 
                map(consumer => consumer.id)
            ));
            if(consumerId){
                this.validNumber = true;
                this.consumerId = consumerId;
                this.errMsg = null;
                let x = await firstValueFrom(this.apiService.sendPasswordAuthToken(this.customerStateService.customerId, this.phoneNumber));
                console.log("Sent")
            }
        } else {
            this.errMsg = "* Please enter a valid phone number."
        }
    }

    resetPassword(){
        this.errMsg = null;
        if(this.newPassword == this.confirmPassword){
            let response = firstValueFrom(this.apiService.resetPassword(this.phoneNumber, this.newPassword, this.confirmPassword, this.customerStateService.customerId, this.tfaToken).
                pipe(catchError(err => {
                    console.log(err)
                    if(err.error.token[0]){
                        this.errMsg = `* ${err.error.token[0]}.`
                    } else if(err.error.customerId.length > 0){
                        this.errMsg = `* ${err.error.customerId[0]}.`
                    } else if(err.error.customerId[0]){
                        this.errMsg = `* ${err.error.confirmPassword[0]}.`
                    } else {
                        this.errMsg = `* An unknown error occurred, please try again.`
                    }
                    return of(null);
                })));
            console.log("Reset completed");
        } else {
            this.errMsg = "* Passwords do not match."
        }
    }

    ngOnDestroy(){

    }
}