import { Component, ElementRef, HostListener, Input, ViewChild, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { ICustomerPass } from 'src/models/customer-pass';
import { ISiteProduct, ISiteProductDescription } from 'src/models/site-product';
import { CustomerStateService } from 'src/services/customer-state.service';
import { PassPurchaseService } from 'src/services/pass-purchase.service';
import { Subject, takeUntil, first, last, BehaviorSubject } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'new-product-banner',
    templateUrl: './new-product-banner.component.html',
    styleUrls: ['../../../scss/product-banners.scss']
})
export class NewProductBannerComponent implements OnDestroy, OnInit, AfterViewInit{
    private readonly onDestroy$ = new Subject<void>();
    public platinum : string = "bace3dcf-4f01-44f7-96dd-cd1e80c2952c";
    public gold: string = "227316fa-af47-4811-8992-949be9c35fe3";
    public silver: string = "170c2796-cb17-4fcb-87e5-d9f778df1cc0";
    public bronze: string = "6db6b50e-f031-4fa8-b706-08df3ad5cd9f";


    @ViewChild('passTitle', { static: true }) passTitle: ElementRef<HTMLDivElement>;
    @ViewChild('priceWrapper', { static: true }) priceWrapper: ElementRef<HTMLDivElement>;
    @ViewChild('bannerBase', { static: true }) bannerBase: ElementRef<HTMLCanvasElement>;
    @ViewChild('treatmentBanner', { static: false }) treatmentBanner: ElementRef<HTMLCanvasElement>;

    @Input() pass: ICustomerPass;

    @Input() products: ISiteProduct[];
    @Input() showDetails: boolean = false;
    @Input() size: number = null;
    @Input() tier: string;
    @Input() tierName: string;
    @Input() isGenericPass: boolean = false;

    public passPrice: string;
    public bannerId: string = null;

    public productDescriptions: ISiteProductDescription[]; //treatment icons
    public productIcons: SafeResourceUrl[];
    private tierLevel: number;

    // customer dynamic styles/images
    public baseColor: string;
        private red: number;
        private green: number;
        private blue: number;
    private lightColor: string;
    private darkColor: string;
    public primaryTextColor: string;
    public productIconBannerColor = '#262626';
    public backgroundClass: string = '';

    private bannerStripes = document.createElement("canvas");
    private canvasHeight = 54;
    private stripeWidths = [28, 52, 35, 150]; // Distance covered by a stripe, width of thick stripe, light thin stripe, total width of stripes


    @HostListener('window:resize')
    windowResize() {
        this.drawBanner(this.bannerBase);
        this.checkName();
        if(this.showDetails) {
            this.drawTreatmentBanner(this.treatmentBanner);  
        }
    }

    constructor(
        private passPurchaseService: PassPurchaseService,
        private customerStateService: CustomerStateService
        ) {
            this.bannerId = Math.random().toString()
        }

    ngOnInit(){
        if(!this.size && !this.passPrice){
            this.passPurchaseService.price.subscribe(
                data =>{
                    let cents: string = '00';
                    if(this.pass){
                        this.passPrice = Math.floor(this.pass.passCost).toString()
                        cents = this.pass.passCostCents ? this.pass.passCostCents.toString().length == 2 ? this.pass.passCostCents.toString() : '0' + this.pass.passCostCents : '00';
                    } else this.passPrice = Math.floor(data).toString()

                    if(cents == '0') cents = "00";
                    this.passPrice += '.'+cents;
                });
        }
        
        if(this.products != null){
            //Setting tier levels in product banner via products
            if(this.products.find(t => t.productTemplateId === this.platinum)) { this.tier = 'platinum'; this.baseColor = document.documentElement.style.getPropertyValue('--theme-tier-4-banner-color'); this.tierLevel = 4;}
            else if(this.products.find(t => t.productTemplateId === this.gold)) { this.tier = 'gold'; this.baseColor = document.documentElement.style.getPropertyValue('--theme-tier-3-banner-color'); this.tierLevel = 3;}
            else if(this.products.find(t => t.productTemplateId === this.silver)) { this.tier = 'silver'; this.baseColor = document.documentElement.style.getPropertyValue('--theme-tier-2-banner-color'); this.tierLevel = 2;} 
            else if (this.products.find(t => t.productTemplateId === this.bronze)) { this.tier = 'bronze'; this.baseColor = document.documentElement.style.getPropertyValue('--theme-tier-1-banner-color'); this.tierLevel = 1;}
        }
        else if(this.pass != null){
            //Setting tier levels in product banner via pass.products
            if(this.pass.products.find(t => t.productTemplateId === this.platinum)) { this.tier = 'platinum'; this.baseColor = document.documentElement.style.getPropertyValue('--theme-tier-4-banner-color'); this.tierLevel = 4;}
            else if(this.pass.products.find(t => t.productTemplateId === this.gold)) { this.tier = 'gold'; this.baseColor = document.documentElement.style.getPropertyValue('--theme-tier-3-banner-color'); this.tierLevel = 3;}
            else if(this.pass.products.find(t => t.productTemplateId === this.silver)) { this.tier = 'silver'; this.baseColor = document.documentElement.style.getPropertyValue('--theme-tier-2-banner-color'); this.tierLevel = 2;} 
            else if (this.pass.products.find(t => t.productTemplateId === this.bronze)) { this.tier = 'bronze'; this.baseColor = document.documentElement.style.getPropertyValue('--theme-tier-1-banner-color'); this.tierLevel = 1;}
        } else if(this.tier != null){
            this.tierLevel = this.tier == 'platinum' ? 4 : this.tier == 'gold' ? 3 : this.tier == 'silver' ? 2 : this.tier == 'bronze' ? 1 : 0;
            this.baseColor = document.documentElement.style.getPropertyValue(`--theme-tier-${this.tierLevel}-banner-color`)
            
        } else {
            this.tierName = 'unknown';
        }
        this.checkName()

        // Only subscribes if the banner is initialized before the customer state service's customerInfo has initialized. 
        if(this.baseColor == '' || this.baseColor == null){
            // Makes sure the colors are properly updated to match customerInfo.
                // Banner colors are set on customerId update, which then triggers customerInfo to update, so getPropertyValue
                // should always hold the correct values by the time the below subscription is triggered to read them
            this.customerStateService.currentCustomerInfo$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
                this.baseColor = document.documentElement.style.getPropertyValue(`--theme-tier-${this.tierLevel}-banner-color`);
                this.initPath();
            })
        }

        if(this.size){
            this.isGenericPass = true;
            this.passTitle.nativeElement.style.setProperty("margin-left", "10px")
            this.bannerBase.nativeElement.style.setProperty("border-radius", "3px 3px / 3px 3px 0 0")
        }

        if(this.showDetails){
            this.backgroundClass = "with-background";
        }
    }

    ngAfterViewInit(){
        this.initPath();
    }

    ngOnDestroy(){
        this.onDestroy$.next(null);
    }

    max255(val: number){
        if (val>255) val = 255;
        return val;
    }

    public drawBanner(bannerBase: ElementRef, widthScalar:number=1) {
        const canvas = bannerBase.nativeElement;
        canvas.width = this.size ?? window.innerWidth*widthScalar;      
        canvas.height = this.canvasHeight;
        let ctx = canvas.getContext('2d');
        let vw = canvas.width/100.0;
        let vwmax = canvas.width;
      
        ctx.fillStyle = this.baseColor;
        ctx.fillRect(0, 0, vwmax, this.canvasHeight);
        
        // New new version
        const endCapBuffer = (vwmax < 960 ? 58 : vwmax > 1493 ? 90 : 6.042*vw);
        const startPoint = vwmax - 8.5*vw - endCapBuffer - this.stripeWidths[3] - this.stripeWidths[1];
        const capStart = startPoint + 88 + this.stripeWidths[1] + this.stripeWidths[2];
        moveTo(startPoint, this.canvasHeight);
      
        const textCollisionWidth = this.size ? 1230 : 560;
        //path for banner lines
        if (vwmax > textCollisionWidth) {              
            canvas.getContext("2d").drawImage(this.bannerStripes, startPoint, 0);
        }
      
        // darker end cap
        ctx.moveTo(capStart, this.canvasHeight); // bottom left
        ctx.beginPath();
        ctx.lineTo(capStart+this.stripeWidths[0], 0); //top left
        ctx.lineTo(vwmax, 0);
        ctx.lineTo(vwmax, this.canvasHeight);
        ctx.lineTo(capStart, this.canvasHeight);
        
        ctx.closePath();
        ctx.fillStyle = this.darkColor;
        ctx.fill();
      
    }
      
    public drawTreatmentBanner(treatmentBanner: ElementRef, widthScalar:number=1){
        const canvas = this.treatmentBanner.nativeElement;
        canvas.width = window.innerWidth*widthScalar;
        
        canvas.height = 52;
        let ctx = canvas.getContext('2d');
        ctx.fillStyle = this.productIconBannerColor;
        ctx.fillRect(0,0,canvas.width,canvas.height);
    }

    public checkName(){
        if(this.size || window.innerWidth < 395 || this.isGenericPass) {
            // banner is small so setting name based on shorter override names
            let customer = this.customerStateService.customerInfo
            if(!customer){
                customer = this.customerStateService.customerDetails.customer
            }
            if(this.tier == 'platinum'){
                this.tierName = customer.platinumTierNameOverride;
            } else if(this.tier == 'gold'){
                this.tierName = customer.goldTierNameOverride;
            } else if(this.tier == 'silver'){
                this.tierName = customer.silverTierNameOverride
            } else if(this.tier == 'bronze'){
                this.tierName = customer.bronzeTierNameOverride
            } else {
                this.tierName = 'unknown'
            }
        } else {
            this.tierName = this.pass.passName;
        }
    }

    initPath(){
        if (this.baseColor != '') {
            this.red = parseInt(this.baseColor.substring(1,3),16);
            this.green = parseInt(this.baseColor.substring(3,5),16);
            this.blue = parseInt(this.baseColor.substring(5,7),16);
            
            this.lightColor = "rgb("+(this.red+35)+' '+(this.green+35)+' '+(this.blue+35)+')';
            this.darkColor = "rgb("+(this.red-17)+' '+(this.green-17)+' '+(this.blue-17)+')';
        
            this.bannerStripes.height = this.canvasHeight;
            this.bannerStripes.width = this.stripeWidths[3];

            let ctx = this.bannerStripes.getContext("2d");
            ctx.moveTo(0,this.canvasHeight);
            ctx.beginPath();
            ctx.lineTo(this.stripeWidths[3]-this.stripeWidths[0], this.canvasHeight);
            ctx.lineTo(this.stripeWidths[3], 0);
            ctx.lineTo(this.stripeWidths[0], 0);
            ctx.lineTo(0, this.canvasHeight);
            ctx.closePath();
            ctx.fillStyle = this.darkColor;
            ctx.fill();

            ctx.moveTo(this.stripeWidths[1], this.canvasHeight);
            ctx.beginPath();
            ctx.lineTo(this.stripeWidths[1]+this.stripeWidths[0], 0);
            ctx.lineTo(this.stripeWidths[0]+this.stripeWidths[1]+this.stripeWidths[2], 0);
            ctx.lineTo(this.stripeWidths[1]+this.stripeWidths[2], this.canvasHeight);
            ctx.lineTo(this.stripeWidths[1], this.canvasHeight);
            ctx.closePath();
            ctx.fillStyle = this.lightColor;
            ctx.fill();

            this.windowResize();
        }
    }
}