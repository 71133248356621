import { Component, Input, HostListener } from "@angular/core";

@Component({
    selector: 'sparkles-icon',
    templateUrl: './sparkles.svg',
    styleUrls: ['sparkles.svg.component.scss']
})
export class BannerSparklesSvgComponent {
    @Input() tier: string;
    @Input() customScalar: number = 1;

    //-- Variables used for drawing the svg --//
        // Initializing to 0 since all elements will need to be read in sparkles.svg
    public tierPaths = ['0','0','0','0','0','0'];
        // Contain the data for the svg paths of each tier
        // tierPaths[x] = path used by Path_x in the svg
    
    public tierTransforms = ['translate(0)','0','0','0','0','0'];
        // Contain the transformations used for the svg paths of each tier
        // tierTransforms[x] = transform used on Path_x in ./sparkles.svg. Corresponds to tierPaths[x]
        // 4, 5 only used in platinum. For smaller platinum sparkle
    public groupTransforms = ['translate(0)','translate(0)','0'];
        // Transformations for each group used in ./sparkles.svg
        // groupTransforms[x] = transform used on Group_x in ./sparkles.svg

    public viewBox = "0 0 0 0";
    public height: number;
    public width: number;
    private heightScalar: number = 2.15;

    constructor(){}

    @HostListener('window:resize')
    svgResize() {
        const maxWidth = window.innerWidth;
        this.height = (maxWidth < 960 ? 33 : maxWidth > 1449 ? 50 : maxWidth * 0.0345) * this.customScalar;
        this.width = this.height*this.heightScalar;
    }

    ngOnInit(): void {
      var tierLevel: string;
      
      switch(this.tier) {
        case "platinum":
          tierLevel = '4';
          //heightScalar 2.15 by default
          this.viewBox = "0 0 99.001 47.296";
          
          //Group_0: parent group for all groups
          this.groupTransforms[0] = "translate(-422.999 -4574.003)";
          //Group_1: group for paths used in every tier
          this.groupTransforms[1] = "translate(422.999 4574.003)"; 
          // Path_0
          this.tierPaths[0] = "M147.671,88.924a1.367,1.367,0,0,1-1.367-1.367c0-13.391-5.59-19.668-18.124-20.343-8.264.576-11.951,4.862-11.951,13.886a1.367,1.367,0,1,1-2.734,0c0-9.783-4.174-13.957-13.957-13.957a1.367,1.367,0,0,1,0-2.734c9.783,0,13.957-4.174,13.957-13.958a1.367,1.367,0,0,1,2.734,0c0,9.024,3.687,13.31,11.951,13.886C140.714,63.663,146.3,57.386,146.3,44a1.367,1.367,0,1,1,2.734,0c0,13.391,5.59,19.668,18.125,20.343,8.264-.576,11.951-4.862,11.951-13.886a1.367,1.367,0,0,1,2.734,0c0,9.783,4.174,13.958,13.957,13.958a1.367,1.367,0,1,1,0,2.734c-9.783,0-13.957,4.174-13.957,13.957a1.367,1.367,0,1,1-2.734,0c0-9.024-3.686-13.31-11.95-13.886-12.535.675-18.125,6.952-18.125,20.343A1.367,1.367,0,0,1,147.671,88.924Z";
          this.tierTransforms[0] = "translate(-98.171 -42.628)";
          // Path_1
          this.tierPaths[1] = "M342.67,128.19a17.13,17.13,0,0,1,11.769,11.758,17.13,17.13,0,0,1,11.769-11.758,17.131,17.131,0,0,1-11.769-11.759A17.13,17.13,0,0,1,342.67,128.19Z";
          this.tierTransforms[1] = "translate(-304.939 -105.042)";
          // Path_2
          this.tierPaths[2] = "M585.967,154.054A12.1,12.1,0,0,1,593,161.093a12.138,12.138,0,0,1,7.043-7.039A12.138,12.138,0,0,1,593,147.015,12.1,12.1,0,0,1,585.967,154.054Z";
          this.tierTransforms[2] = "translate(-510.69 -130.906)";
          // Path_3
          this.tierPaths[3] = "M160.67,154.054a12.137,12.137,0,0,1,7.043,7.039,12.1,12.1,0,0,1,7.036-7.039,12.1,12.1,0,0,1-7.036-7.039A12.138,12.138,0,0,1,160.67,154.054Z";
          this.tierTransforms[3] = "translate(-151.025 -130.906)";
          
          //Group_2: group for small sparkle only in platinum 
          this.groupTransforms[2] = "translate(481.534 4575.213)"; 
          // Path_4
          this.tierPaths[4] = "M139.758,2316.71a.641.641,0,0,1-.641-.641c0-5.711-2.547-8.258-8.258-8.258a.641.641,0,0,1,0-1.283c5.711,0,8.258-2.547,8.258-8.259a.641.641,0,1,1,1.283,0c0,5.711,2.547,8.259,8.258,8.259a.641.641,0,0,1,0,1.283c-5.711,0-8.258,2.546-8.258,8.258A.641.641,0,0,1,139.758,2316.71Z";
          this.tierTransforms[4] = "translate(-131.427 -2298.838)";
          // Path_5
          this.tierPaths[5] = "M208.8,2380.178a6.135,6.135,0,0,1,3.966,3.966,3.967,3.967,0,0,1,3.966-3.966,6.135,6.135,0,0,1-3.966-3.966A6.135,6.135,0,0,1,208.8,2380.178Z";
          this.tierTransforms[5] = "translate(-204.433 -2371.847)";
          
          break;
        case "gold":
          tierLevel = '3';
          //heightScalar 2.15 by default
          this.viewBox = "0 0 99.001 47.296";
          
          // No transformation for Group_0 or Group_1 
          // Path_0
          this.tierPaths[0] = "M147.671,88.924a1.367,1.367,0,0,1-1.367-1.367c0-13.391-5.59-19.668-18.124-20.343-8.264.576-11.951,4.862-11.951,13.886a1.367,1.367,0,1,1-2.734,0c0-9.783-4.174-13.957-13.957-13.957a1.367,1.367,0,0,1,0-2.734c9.783,0,13.957-4.174,13.957-13.958a1.367,1.367,0,0,1,2.734,0c0,9.024,3.687,13.31,11.951,13.886C140.714,63.663,146.3,57.386,146.3,44a1.367,1.367,0,1,1,2.734,0c0,13.391,5.59,19.668,18.125,20.343,8.264-.576,11.951-4.862,11.951-13.886a1.367,1.367,0,0,1,2.734,0c0,9.783,4.174,13.958,13.957,13.958a1.367,1.367,0,1,1,0,2.734c-9.783,0-13.957,4.174-13.957,13.957a1.367,1.367,0,1,1-2.734,0c0-9.024-3.686-13.31-11.95-13.886-12.535.675-18.125,6.952-18.125,20.343A1.367,1.367,0,0,1,147.671,88.924Z";
          this.tierTransforms[0] = "translate(-98.171 -42.628)";
          // Path_1
          this.tierPaths[1] = "M342.67,128.19a17.13,17.13,0,0,1,11.769,11.758,17.13,17.13,0,0,1,11.769-11.758,17.131,17.131,0,0,1-11.769-11.759A17.13,17.13,0,0,1,342.67,128.19Z";
          this.tierTransforms[1] = "translate(-304.939 -105.042)";
          // Path_2
          this.tierPaths[2] = "M585.967,154.054A12.1,12.1,0,0,1,593,161.093a12.138,12.138,0,0,1,7.043-7.039A12.138,12.138,0,0,1,593,147.015,12.1,12.1,0,0,1,585.967,154.054Z";
          this.tierTransforms[2] = "translate(-510.69 -130.906)";
          // Path_3
          this.tierPaths[3] = "M160.67,154.054a12.137,12.137,0,0,1,7.043,7.039,12.1,12.1,0,0,1,7.036-7.039,12.1,12.1,0,0,1-7.036-7.039A12.138,12.138,0,0,1,160.67,154.054Z";
          this.tierTransforms[3] = "translate(-151.025 -130.906)";
          break;
        case "silver":
          tierLevel = '2';
          this.heightScalar = 1.45;
          this.viewBox = "0 0 57.234 41";
          
          // Initial Group_1 transformation
          this.groupTransforms[1] = "translate(-130.217 -1169.878)"; //144
          // Path_0, no transformation applied
          this.tierPaths[0] = "M169.214,1209.878a1.133,1.133,0,0,1-1.077-1.181c0-11.57-4.4-16.993-14.278-17.577-6.51.5-9.415,4.2-9.415,12a1.082,1.082,0,1,1-2.154,0c0-8.453-3.289-12.059-11-12.059a1.186,1.186,0,0,1,0-2.362c7.707,0,11-3.606,11-12.059a1.082,1.082,0,1,1,2.154,0c0,7.8,2.9,11.5,9.415,12,9.874-.583,14.278-6.006,14.278-17.576a1.082,1.082,0,1,1,2.154,0c0,12.2,4.96,17.638,16.083,17.638a1.186,1.186,0,0,1,0,2.362c-11.123,0-16.083,5.44-16.083,17.638A1.132,1.132,0,0,1,169.214,1209.878Z";
          // Path_1
          this.tierPaths[1] = "M353.158,1253.791c4.7,1.639,7.774,5.008,9.274,10.167,1.5-5.149,4.579-8.521,9.276-10.167-4.7-1.646-7.774-5.018-9.276-10.167C360.932,1248.782,357.857,1252.152,353.158,1253.791Z";
          this.tierTransforms[1] = "translate(-193.216 -63.913)"; //193
          // Path_2
          this.tierPaths[2] = "M187.206,1280.347a10.07,10.07,0,0,1,5.549,6.081,10.042,10.042,0,0,1,5.543-6.081,10.044,10.044,0,0,1-5.543-6.082A10.07,10.07,0,0,1,187.206,1280.347Z";
          this.tierTransforms[2] = "translate(-49.39 -90.469)";  //49
          break;
        case "bronze":
          tierLevel = '1';
          this.heightScalar = 1;
          this.viewBox = "0 0 40 42";
          
          // Group_1 initial transformation
          this.groupTransforms[1] = "translate(-130.217 -2297.628)"; //153
          // Path_0, no transformation
          this.tierPaths[0] = "M150.216,2337.628a1.344,1.344,0,0,1-1.344-1.344c0-11.972-5.339-17.311-17.311-17.311a1.344,1.344,0,1,1,0-2.689c11.972,0,17.311-5.339,17.311-17.312a1.344,1.344,0,1,1,2.689,0c0,11.972,5.339,17.312,17.311,17.312a1.344,1.344,0,1,1,0,2.689c-11.972,0-17.311,5.339-17.311,17.311A1.344,1.344,0,0,1,150.216,2337.628Z";
          // Path_1
          this.tierPaths[1] = "M208.8,2385.734a14.728,14.728,0,0,1,9.522,9.522,14.73,14.73,0,0,1,9.522-9.522,14.729,14.729,0,0,1-9.522-9.522A14.727,14.727,0,0,1,208.8,2385.734Z";
          this.tierTransforms[1] = "translate(-68.103 -68.106)"; //68
          break;
        default:
          console.error("Unknown tier name: ", this.tier, "\n  Exepected platinum, gold, silver, or bronze");
          break;
      }
      this.svgResize();
  }
  

  max255(val: number){
    if (val>255) val = 255;
    return val;
  }
}