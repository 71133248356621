import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Observable, Subject, first, firstValueFrom, map, takeUntil } from 'rxjs';
import { Addresses } from 'src/models/addresses';
import { IConsumer } from 'src/models/consumer';
import { ConsumerLogin } from 'src/models/consumer-login';
import { AlertService } from 'src/services/alert.service';
import { ApiService } from 'src/services/api.service';
import { CustomerStateService } from 'src/services/customer-state.service';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {
  consumerForm!: FormGroup;
  
  public customerId$: Observable<string>;
  public customerId: string;
  public consumerId$: Observable<string>;
  public consumer$: Observable<IConsumer>;
  private readonly onDestroy$ = new Subject<void>();
  public consumer: IConsumer;
  public viewAddresses: Addresses[];
  public consumerLogin: ConsumerLogin;
  loading = false;

  public signupErrors: string[];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private alertService: AlertService,
    public customerStateService: CustomerStateService
  ) { }
  
  async ngOnInit() {
    
    this.customerId = this.customerStateService.customerId ? this.customerStateService.customerId : await firstValueFrom(this.route.root.params.pipe(map(x => x.customerId)));

    var mobileNumber = sessionStorage.getItem("mobileNumber")
    if (mobileNumber != null)
    {
      this.customerStateService.mobileNumber = mobileNumber
    }

    // Edit account
    this.consumerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobileNumber: ['', [Validators.required, Validators.pattern('[- +()0-9]{10,12}')]],
      email: ['', Validators.required],

      street: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      stateOrProvince: ['', Validators.required],
      zip: ['', Validators.required],

      password: ['', Validators.minLength(8)],
      confirmPassword: ['', Validators.minLength(8)],
    });
    
    this.consumerForm.patchValue({
      mobileNumber: this.customerStateService.mobileNumber
    });
    
  }

  get consumerValue() { return this.consumerForm.controls; }

  get firstName() { return this.consumerForm.get('firstName'); }
  get lastName() { return this.consumerForm.get('lastName'); }
  get mobileNumber() { return this.consumerForm.get('mobileNumber'); }
  get email() { return this.consumerForm.get('email'); }

  get addressType() { return this.consumerForm.get('addressType'); }
  get street() { return this.consumerForm.get('street'); }
  get city() { return this.consumerForm.get('city'); }
  get country() { return this.consumerForm.get('country'); }
  get stateOrProvince() { return this.consumerForm.get('stateOrProvince'); }
  get zip() { return this.consumerForm.get('zip'); }

  get password() { return this.consumerForm.get('password'); }
  get confirmPassword() { return this.consumerForm.get('confirmPassword'); }


  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  async createAccount() {
    return this.apiService.createAccount(this.customerId, this.consumerValue)
  }
  
  registerOnChange(onChange: any) {
    this.consumerForm.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(onChange);
  }

  registerOnTouched(onTouch: any) {
    this.consumerForm.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(onTouch);
  }

  validate() {
    return this.consumerForm.valid ? null : { invalidForm: 'Entry form is invalid' };
  }

  async savePersonalInfo() {
    console.log("Customer id", this.customerId)
    if (this.consumerForm.value)
    {
        this.apiService.createAccount(this.customerId, <Partial<IConsumer>>this.consumerForm.value).pipe(first()).subscribe({
          next: c => {
          this.apiService.login(this.mobileNumber.value, this.password.value, this.customerId)
          .pipe(first()).subscribe({
            next: consumer => {
              this.consumerForm.setErrors({ unauthenticated: false });
              this.consumerLogin = consumer;
              this.apiService.getConsumerDetails(consumer.consumer.customerId, this.mobileNumber.value).pipe(first()).subscribe(
                consumerDetails => {
                  this.customerStateService.consumerDetails = consumerDetails;
                })
              this.apiService.getCustomerDetails(consumer.consumer.customerId, consumer.token).pipe(first()).subscribe(
                customerDetails => {
                  this.customerStateService.customerDetails = customerDetails;
                  this.router.navigate([`/customer/${this.customerStateService.customerId}/manage-my-membership/dashboard`])
                })
            },
            error: error => {
              this.loading = false; 
              this.consumerForm.setErrors({ unauthenticated: true });
              this.consumerForm.controls['password'].setValue('');
              this.consumerForm.controls['confirmPassword'].setValue('');
              this.alertService.error(error);
            }
          })
        },
        error: error => {
          this.loading = false; 
          this.consumerForm.setErrors({ unauthenticated: true });
          this.consumerForm.controls['password'].setValue('');
          this.consumerForm.controls['confirmPassword'].setValue('');
          this.signupErrors = Object.values(error.error)
          this.openErrorModal()
          setTimeout(() => this.closeErrorModal(), 6000);
          this.alertService.error(error);
        }
    })

        
    }
  }

  closeErrorModal() {
    const modalDiv = document.getElementById("error-modal");
      if (modalDiv != null) {
        modalDiv.style.display = "none";
      }
  }
  
  async openErrorModal() {
    // Add your button click logic here
    console.log("Buy Button clicked!");
    
    //If not loaded
      //open the modal.
      const modalDiv = document.getElementById("error-modal");
      const modalBg = document.getElementById("modal-background");
      if (modalDiv != null) {
        modalDiv.style.display = "block";
        modalBg.style.position = "fixed";
        modalBg.style.top = "0";
        modalBg.style.bottom = "0";
        modalBg.style.right = "0";
        modalBg.style.left = "0";
        modalBg.style.backgroundColor = "#000";
        modalBg.style.opacity = "0.75";
        modalBg.style.zIndex = "-1";
      }
      //show the closest 5 sites. (api call.)
      //android app uses this api call. 
      //pick a site and then re-navigate to the landing page
      //this time with the customer id of the site selected.

  }
}
