import { Component, OnInit, Input  } from '@angular/core';
import { ISiteProduct } from 'src/models/site-product';
import { PassPurchaseService } from 'src/services/pass-purchase.service';

@Component({
    selector: 'buy-pass-btn',
    templateUrl: './new-buy-pass-btn.component.html',
    styleUrls: ['../../../scss/pass-purchase.scss']
})
export class BuyPassBtn implements OnInit{

    @Input() products: ISiteProduct[];

    public tier: string;

    constructor(private passPurchaseService: PassPurchaseService){}

    ngOnInit(): void{
        if(this.products.find(t => t.productTemplateId === this.passPurchaseService.platinum)) { this.tier = 'platinum'; }
        else if(this.products.find(t => t.productTemplateId === this.passPurchaseService.gold)) { this.tier = 'gold'; }
        else if(this.products.find(t => t.productTemplateId === this.passPurchaseService.silver)) { this.tier = 'silver'; } 
        else if (this.products.find(t => t.productTemplateId === this.passPurchaseService.bronze)) { this.tier = 'bronze'; }
    }
}