import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AlertComponent } from "./alert/alert.component";
import { StaticHtmlComponent } from "./static-html/static-html.component";
import { ProductDescriptionImageComponent } from "./product-description-image/product-description-image.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NewProductBannerComponent } from "./new-product-banner/new-product-banner.component";
import { PageHeaderComponent } from "./page-header/page-header.component";
import { BuyPassBtn } from "./new-buy-pass-btn/new-buy-pass-btn.component";
import { BannerSparklesSvgComponent } from "./svgs/buy-this-monthly-pass/sparkles-svg/sparkles.svg.component";
import { PurchaseFooterComponent } from "./purchase-footer/purchase-footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { BackButtonComponent } from "./back-button/back-button.component";


@NgModule({
    declarations: [
        StaticHtmlComponent,
        AlertComponent,
        PageHeaderComponent,
        ProductDescriptionImageComponent,
        NewProductBannerComponent,
        PurchaseFooterComponent,
        BuyPassBtn,
        BannerSparklesSvgComponent,
        NavbarComponent,
        BackButtonComponent
    ],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      RouterModule,
    ],
    exports: [
        StaticHtmlComponent,
        AlertComponent,
        PageHeaderComponent,
        ProductDescriptionImageComponent,
        NewProductBannerComponent,
        PurchaseFooterComponent,
        BuyPassBtn,
        BannerSparklesSvgComponent,
        NavbarComponent,
        BackButtonComponent
    ]
})
export class SharedModule { }