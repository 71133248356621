import { Component, OnInit, HostBinding } from '@angular/core';
import { Observable, Subject, firstValueFrom, map, switchMap, combineLatest, first } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { propertyOf } from 'src/app/utils/string-helpers';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { TextReceiptInformation } from 'src/models/text-receipt-information';
import { ApiService } from 'src/services/api.service';
import { MobileService } from "src/services/mobile.service";
import { WNCVVResponses, WNAVSResponses, WNTransactionResponses, WNPaymentResponse } from 'src/models/worldnet-models';
import { CustomerStateService } from 'src/services/customer-state.service';
import { PassPurchaseService } from 'src/services/pass-purchase.service';

@Component({
  selector: 'app-reciept',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {
  @HostBinding('style.--primaryColor')

  //-- Receipt page initializing variables --//
  isMobile: boolean;
  public initialized: boolean = false;
  public fromWebPass: boolean = false; // if true, payment response needs processed and sent to api
  public textReceiptInformation: TextReceiptInformation;
  public textReceipt$: Observable<TextReceiptInformation>;
  public notLoadedMsgs = ["Loading...", "Please wait while we process your receipt."];

  private readonly onDestroy$ = new Subject<void>();

  //-- Wash pass information --//
  public customerName: string;
  public passName: string;
  public promoName: string;
  public siteAddress1: string;
  public siteAddress2?: string;
  public siteLocation1: string;
  public paymentTimestamp: string;
  
  //-- Contact information --//
  public phoneNumber: string;
  public email: string;
  public fromName: string;
  public toName: string;
  public purchaserNumber: string;
  public recipientPhoneNumber: string;
  
  //-- Gift pass date info --//
  public numberOfMonths: string;
  public activationDate: string;
  
  //-- Pricing information --//
  public monthlyPrice: number;
  public promoPrice: number;
  public subtotal: number;
  public tax: number;
  public surcharge: number;
  public paymentAmount: number;

  //-- URLs and asset variables --//
  public thankYouUrl: string = null;
  public primaryTextColor: string;
  public secondaryTextColor: string;
  public primaryColor: string;
  public neutralBgColor: string;
  public secondaryBgColor: string;
  public additionalProperty1: string;
  public additionalProperty2: string;
  public additionalUrl: string;
  public visualUrl: string;
  public image: SafeResourceUrl;
  public backgroundImage: string = 'url("src/assets/Splash_Background.jpg")';
  public instagramLink: string;
  public facebookLink: string;
  public youtubeLink: string;
  public mobileDownload: string;

  constructor(
    public customerStateService: CustomerStateService,
    private passPurchaseService: PassPurchaseService,
    private apiService: ApiService,
    public sanitizer:DomSanitizer,
    private route: ActivatedRoute,
    private mobileService: MobileService,
    private router: Router
  ) {
    this.isMobile = this.mobileService.isMobile();
  }

  async ngOnInit() {
    this.fromWebPass = await firstValueFrom(this.route.params.pipe(
      takeUntil(this.onDestroy$),
      map(param => param['id'] == 'webPass')
    ))

    if(this.fromWebPass){
//=====================   Web pass purchase receipt ====================//
    this.route.queryParamMap.pipe(takeUntil(this.onDestroy$)).subscribe(
      paramMap => {
        const orderIdPropName: keyof WNPaymentResponse = 'ORDERID';
        const responseCodePropName: keyof WNPaymentResponse = 'RESPONSECODE';
        if (paramMap.has(orderIdPropName) && paramMap.has(responseCodePropName)) {
          // parameters look like a payment response
          var paymentResponse: WNPaymentResponse = {
            AMOUNT: parseFloat(paramMap.get(propertyOf<WNPaymentResponse>('AMOUNT'))) || null,
            APPROVALCODE: paramMap.get(propertyOf<WNPaymentResponse>('APPROVALCODE')),
            AVSRESPONSE: paramMap.get(propertyOf<WNPaymentResponse>('AVSRESPONSE')) as WNAVSResponses,
            BRANDTXIDENTIFIER: paramMap.get(propertyOf<WNPaymentResponse>('BRANDTXIDENTIFIER')),
            CARDEXPIRY: paramMap.get(propertyOf<WNPaymentResponse>('CARDEXPIRY')),
            CARDNUMBER: paramMap.get(propertyOf<WNPaymentResponse>('CARDNUMBER')),
            CARDREFERENCE: paramMap.get(propertyOf<WNPaymentResponse>('CARDREFERENCE')),
            CARDTYPE: paramMap.get(propertyOf<WNPaymentResponse>('CARDTYPE')),
            COUNTRY: paramMap.get(propertyOf<WNPaymentResponse>('COUNTRY')),
            CUSTOMFIELD: paramMap.get(propertyOf<WNPaymentResponse>('CUSTOMFIELD')),
            CVVRESPONSE: paramMap.get(propertyOf<WNPaymentResponse>('CVVRESPONSE')) as WNCVVResponses,
            DATETIME: paramMap.get(propertyOf<WNPaymentResponse>('DATETIME')),
            EMAIL: paramMap.get(propertyOf<WNPaymentResponse>('EMAIL')),
            FRAUDREVIEWREASONCODE: paramMap.get(propertyOf<WNPaymentResponse>('FRAUDREVIEWREASONCODE')),
            FRAUDREVIEWRISKRATING: paramMap.get(propertyOf<WNPaymentResponse>('FRAUDREVIEWRISKRATING')) as any,
            FRAUDREVIEWSCORE: parseFloat(paramMap.get(propertyOf<WNPaymentResponse>('FRAUDREVIEWSCORE'))) || null,
            FRAUDREVIEWSTATUS: paramMap.get(propertyOf<WNPaymentResponse>('FRAUDREVIEWSTATUS')) as any,
            HASH: paramMap.get(propertyOf<WNPaymentResponse>('HASH')),
            ISSTORED: paramMap.get(propertyOf<WNPaymentResponse>('ISSTORED')) as 'true'|'false',
            MERCHANTREF: paramMap.get(propertyOf<WNPaymentResponse>('MERCHANTREF')),
            ORDERID: paramMap.get(propertyOf<WNPaymentResponse>('ORDERID')),
            OTHERFIELD: paramMap.get(propertyOf<WNPaymentResponse>('OTHERFIELD')),
            RESPONSECODE: paramMap.get(propertyOf<WNPaymentResponse>('RESPONSECODE')) as WNTransactionResponses,
            RESPONSETEXT: paramMap.get(propertyOf<WNPaymentResponse>('RESPONSETEXT')),
            SCERROR: paramMap.get(propertyOf<WNPaymentResponse>('SCERROR')),
            STOREDCREDENTIALTXTYPE: paramMap.get(propertyOf<WNPaymentResponse>('STOREDCREDENTIALTXTYPE')),
            STOREDCREDENTIALUSE: paramMap.get(propertyOf<WNPaymentResponse>('STOREDCREDENTIALUSE')),
            UNIQUEREF: paramMap.get(propertyOf<WNPaymentResponse>('UNIQUEREF')),
            ACTIVATIONDATE: paramMap.get(propertyOf<WNPaymentResponse>('ACTIVATIONDATE')),
            CONSUMERID: paramMap.get(propertyOf<WNPaymentResponse>('CONSUMERID')),
            PASSCOST: paramMap.get(propertyOf<WNPaymentResponse>('PASSCOST')),
            PASSID: paramMap.get(propertyOf<WNPaymentResponse>('PASSID')),
            PROMOID:paramMap.get(propertyOf<WNPaymentResponse>('PROMOID')),
            PHONE: paramMap.get(propertyOf<WNPaymentResponse>('PHONE')),
            FROMNAME: paramMap.get(propertyOf<WNPaymentResponse>('FROMNAME')),
            RECIPIENTPHONE: paramMap.get(propertyOf<WNPaymentResponse>('RECIPIENTPHONE')),
            TONAME: paramMap.get(propertyOf<WNPaymentResponse>('TONAME')),
            PROMOCODE: paramMap.get(propertyOf<WNPaymentResponse>('PROMOCODE')),
            DURATION: paramMap.get(propertyOf<WNPaymentResponse>('DURATION')),
          };
          this.passPurchaseService.setWorldNetReceipt(paymentResponse);
        }
      }
    );

      combineLatest([
        this.passPurchaseService.purchaseDetails$.pipe(takeUntil(this.onDestroy$), first(t => t.receipt != null)),
        this.customerStateService.favoredSite$.pipe(takeUntil(this.onDestroy$), first(t => t != null) ),
        this.customerStateService.currentCustomerInfo$.pipe(takeUntil(this.onDestroy$), first(t => t != null) )
      ]).subscribe(
        async ([purchaseDetails, site, customer]) => {
          const receipt = purchaseDetails.receipt;
          const passId = receipt.PASSID;

          await this.passPurchaseService.getTaxAndSurcharge();
          let taxAndSurcharge = (purchaseDetails.tax ?? 0) + (purchaseDetails.surcharge ?? 0); // percentage calculation of tax + surcharge
          let origPrice = receipt.AMOUNT / (1 + taxAndSurcharge);                                  // price before tax but after promos
          taxAndSurcharge = origPrice * taxAndSurcharge;                                       // dollar value of tax + surcharge
          receipt.TAX = origPrice * purchaseDetails.tax;
          receipt.SURCHARGE = origPrice * purchaseDetails.surcharge;
          if(receipt.PROMOCODE){
            let promoData = await this.apiService.validatePromo(parseInt(receipt.PROMOCODE), receipt.PHONE, customer.id); 
            receipt.PROMOID = promoData.id;
            receipt.PROMONAME = promoData.name;
            this.apiService.redeemPromo(parseInt(receipt.PROMOCODE), receipt.PHONE, customer.id);
          }
          let paymentId: string;
          if (receipt.RESPONSECODE === WNTransactionResponses.APPROVED) {
            // TODO post to the API to make the pass and record the transaction
            if( receipt.EMAIL === ""){
              // If email has not been set, this is a self purchase
              paymentId = await this.passPurchaseService.postPassPurchase(site.id, receipt, passId, customer.mobileDownload, customer.thankYouUrl)
            } else {
              // else this is a gift purchase
              paymentId = await this.passPurchaseService.postGiftPassPurchase(site.id, receipt, passId, customer.mobileDownload, customer.thankYouUrl, receipt.CONSUMERID);
            }
            this.router.navigate(["receipt", paymentId])
            this.initReceipt(await firstValueFrom(this.apiService.setTextReceiptInformation(paymentId)));
          }
        });
    } else {      
      try {
        let guid = await firstValueFrom(this.route.params.pipe(map(params => params['id'])));
        this.initReceipt(await firstValueFrom(this.apiService.setTextReceiptInformation(guid)));
      } catch {
        this.notLoadedMsgs = ["OOPS!", "No purchase details found."];
      }
    }
  }

  initReceipt(receipt: TextReceiptInformation){
    if(receipt != null){
      if(receipt.image)
        this.image = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/svg;base64,' + receipt.image);
      if(receipt.backgroundImage && receipt.backgroundImage != receipt.image)
        this.backgroundImage = "url('data:image/svg;base64," + receipt.backgroundImage + "')";

      this.customerName         = receipt.customerName ?? null;

      this.phoneNumber          = receipt.phoneNumber ?? null;
      
      this.email                = receipt.email ?? null;
      this.fromName             = receipt.purchaserName;
      this.purchaserNumber      = receipt.purchaserNumber ? this.parsePhoneNumber(receipt.purchaserNumber) : null; 
      this.toName               = receipt.recipientName;
      this.recipientPhoneNumber = receipt.recipientNumber ? this.parsePhoneNumber(receipt.recipientNumber) : null;

      this.facebookLink   = receipt.facebookLink ?? null; 
      this.instagramLink  = receipt.instagramLink ?? null;
      this.youtubeLink    = receipt.youtubeLink ?? null;
      this.mobileDownload = receipt.mobileDownload ?? null;
      this.passName       = receipt.passName ?? null;     

      this.primaryColor = "#" + receipt.primaryColor;
      this.primaryTextColor = "#" + receipt.primaryColor;
      document.documentElement.style.setProperty("--primary-text-color", `${this.primaryTextColor}`)

      this.siteAddress1 = receipt.siteAddress1;
      this.siteAddress2 = receipt.siteAddress2 ?? "";
      this.siteLocation1 = receipt.siteLocation1;
      this.paymentAmount = receipt.paymentAmount;
      this.paymentTimestamp = receipt.paymentTimestamp;
      this.additionalUrl = receipt.additionalUrl;
      this.promoName = receipt.promoName;
      this.promoPrice = receipt.promoDiscount;
      this.tax = receipt.paymentTaxAmount;
      this.surcharge = receipt.paymentSurchargeAmount;

      this.monthlyPrice = this.paymentAmount - this.tax - this.surcharge + this.promoPrice;
      this.numberOfMonths = receipt.giftDuration > 0 ? 'x'+receipt.giftDuration : null;

      if (this.additionalUrl != null)
      {
        //this one is used to display the additionalUrl without "https:// and the trailing "/""
        var tempUrl: string;
        if (this.additionalUrl.includes("https")) 
        {
          tempUrl = receipt.additionalUrl.replace("https://", "");
          //replace trailing "/" only with empty "". All other characters are left unchanged
        }
        else
        {
          tempUrl = receipt.additionalUrl.replace("http://", "");
          //replace trailing "/" only with empty "". All other characters are left unchanged
        }
        var lastDot = tempUrl.lastIndexOf(".")
        tempUrl = tempUrl.substring(0, lastDot+4);
        this.visualUrl = tempUrl.replace(/\/(?=[^\/]*$)/, "");
      }
      this.additionalProperty1 = receipt.additionalProperty1;
      this.additionalProperty2 = receipt.additionalProperty2;
      this.initialized = true;
    } else {
      this.notLoadedMsgs = ["OOPS!", "No purchase details found."];
    }
  }

  private parsePhoneNumber(phoneNumber: string): string{
    if (phoneNumber.includes('+')){
      phoneNumber = phoneNumber.substring(phoneNumber.indexOf('+')+2);
    }
    return phoneNumber.substring(0,3)+'-'+phoneNumber.substring(3,6)+'-'+phoneNumber.substring(6,10);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}