import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, first, takeUntil, Subject, firstValueFrom, map } from 'rxjs';
import { IConsumer } from 'src/models/consumer';
import { ConsumerLogin } from 'src/models/consumer-login';
import { CustomerInformation } from 'src/models/customer';
import { AlertService } from 'src/services/alert.service';
import { ApiService } from 'src/services/api.service';
import { CustomerStateService } from 'src/services/customer-state.service';
import Lottie, { AnimationItem } from "lottie-web";
import { jwtDecode } from "jwt-decode";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  modalRef?: BsModalRef;
  phoneNumber!: FormGroup;
  loginForm!: FormGroup;
  submitted = false;

  private readonly onDestroy$ = new Subject<void>();
  public customers: CustomerInformation[] = null;
  public consumerLogin: ConsumerLogin;
  public consumer: IConsumer;
  public customerId$: Observable<string>;
  
  public backgroundImage: string = 'url("../../assets/Splash_Background.jpg")';

  public showLoadScreen: boolean = false;
  private anim: AnimationItem;

  public modal: HTMLElement;
  public modalImg: HTMLImageElement;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    private alertService: AlertService,
    public customerStateService: CustomerStateService
  ) { 
    document.body.style.overflow = 'hidden'
  }
  
  ngOnInit() {
    if (this.router.url.includes('/customer/',0)){
      this.customerStateService.customerId = this.router.url.substring(10,this.router.url.lastIndexOf('/'))
    }
    this.loginForm = this.formBuilder.group({
      customerId: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.pattern('[- +()0-9]{10,12}')]],
      password: ['', Validators.required]
    });
    this.phoneNumber = this.formBuilder.group({
        phoneNumber: ['', [Validators.required, Validators.pattern('[- +()0-9]{10,12}')]],
    });

    this.customerStateService.currentCustomerInfo$.pipe(takeUntil(this.onDestroy$)).subscribe(
      customerInfo =>  {
        this.backgroundImage = customerInfo ? "url('data:image/svg;base64," + customerInfo.backgroundImage : 'url("../../assets/Splash_Background.jpg")'
      })

    this.anim = Lottie.loadAnimation({
      container: document.getElementById('loginLoadingGraphic'),
      renderer: 'svg',
      rendererSettings: {
        className: 'loading-graphic'
      },
      loop: true,
      autoplay: false,
      path: '../../assets/loading_bubbles.json'
    });
    this.modal = document.getElementById('loadingModalOverlay');
  }

  get numberValue() { return this.phoneNumber.controls; }
  get loginValue() { return this.loginForm.controls; }

  async onLogin() {
    this.toggleLoadScreen(true);
    this.apiService.login(this.loginValue.phoneNumber.value, this.loginValue.password.value, this.customerStateService.customerId)
      .pipe(first()).subscribe({ 
      next: async consumer => {
        console.log("Login response:", consumer);
        this.loginForm.setErrors({ unauthenticated: false });
        this.consumerLogin = consumer;
        let decoded = jwtDecode(consumer.token);
        let expiration = decoded.exp*1000;
        document.cookie = `token=${consumer.token};`;
        document.cookie = `expires=${expiration};`;
        console.log("My token:", jwtDecode(consumer.token), "expires", new Date(expiration) );
        this.customerStateService.consumerDetails = await this.apiService.getConsumerDetailsAsync(consumer.consumer.customerId, this.loginValue.phoneNumber.value);
        this.customerStateService.customerDetails = await this.apiService.getCustomerDetailsAsync(consumer.consumer.customerId, 'true');
        
        this.router.navigate([`/customer/${this.customerStateService.customerId}/manage-my-membership/`]);
      },
      error: error => {
        this.toggleLoadScreen(false);
        this.loginForm.setErrors({ unauthenticated: true });
        this.loginForm.controls['password'].setValue('');
        this.alertService.error(error);
      }
    })
    
  }

  onSignup(){
    this.toggleLoadScreen(true);
    this.apiService.signUp(this.numberValue.phoneNumber.value, this.customerStateService.customerId)
      .pipe(first()).subscribe({ 
      next: consumer => {
        this.loginForm.setErrors({ unauthenticated: false });
        
        this.consumer = consumer;
        alert("This phone number already exists: " + consumer.mobileNumber);
      },
      error: error => {
        this.loginForm.setErrors({ unauthenticated: true });
        this.alertService.error(error);
        this.customerStateService.mobileNumber = this.numberValue.phoneNumber.value
        this.router.navigate([`/customer/${this.customerStateService.customerId}/signup`])
      }})
      this.toggleLoadScreen(false);
  }

  async toResetPwdPage(){
    this.router.navigate([`/customer/${this.customerStateService.customerId}/reset-password`])
  }

  toggleLoadScreen(toDisplay: boolean){
    if(toDisplay){
      this.modal.style.display = "flex";
      this.anim.play();
    } else {
      this.modal.style.display = "none";
      this.anim.stop();
    }
  }
  //Remove style on destroy
  ngOnDestroy(): void {
    document.body.style.removeProperty('overflow')
    this.onDestroy$.next();
  }
}
